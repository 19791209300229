<template>
  <v-card outlined :disabled="loading" class="py-2 px-0 mb-4">
    <v-row no-gutters align="center">
      <v-col cols="12" md="12" lg="9" xs="12" class="px-0">
        <v-card-title
          class="pt-2 text-body-1 font-weight-medium text--lighten-3"
          v-text="'Dados do pedido'"
        />
        <v-card-subtitle
          class="pb-3 text-body-2 text--lighten-3"
          v-text="'Principais configurações sobre o pagamento para este pedido'"
        />
      </v-col>
      <v-col
        v-if="isConecta"
        cols="12"
        md="12"
        lg="3"
        :class="`d-flex ${
          $vuetify.breakpoint.mdAndDown ? 'justify-start pb-4' : 'justify-end'
        }`"
      >
        <PurchaseNote
          :key="currentNote"
          :note="currentNote"
          @note="setNote"
          is-new-plan
        />
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="px-4 pt-6 pb-2" align="start">
      <v-col
        cols="12"
        sm="3"
        xs="12"
        v-for="(item, i) in orderConfirmationForm"
        :key="i"
      >
        <DatePicker
          :id="item.key"
          :key="planInCart[item.key]"
          :test-id="item.test_id || ''"
          :label="item.label"
          :info="item.info || ''"
          :disabled="item.disabled || disabled"
          :readonly="item.readonly"
          :min-today="!item.maxToday"
          placeholder="Não há definição de data para esta modalidade"
          outlined
          dense
          hide-details
          :value="planInCart[item.key] || ''"
          @update="updateDate(item, $event)"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import DatePicker from "@/components/base/DatePicker.vue";
import PurchaseNote from "@/components/reseller/dialogs/PurchaseNote.vue";

import {
  formatDate,
  formatDateForDatabase,
  dateIsAfterAnother,
  addDays,
  addYears,
} from "@/helpers/services/utils";

import { setEndTransactionByPeriod } from "@/helpers/services/companyPlan";

import { SUBSCRIPTIONS } from "@/helpers/variables/backendConstants";

import { mapGetters, mapMutations } from "vuex";
import * as moment from "moment";

export default {
  name: "OrderSettings",

  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  components: { DatePicker, PurchaseNote },

  data() {
    return {
      SUBSCRIPTIONS,
    };
  },

  computed: {
    ...mapGetters(["planInCart", "isConecta"]),

    orderConfirmationForm() {
      return [
        {
          text_id: "end-trial-date-purchase",
          label: "Encerramento do trial",
          value: this.planInCart ? this.planInCart.end_trial : "",
          minToday: false,
          maxToday: false,
          readonly: false,
          visible: true,
          key: "end_trial",
        },
        {
          text_id: "renewal-date-purchase",
          label: "Renovação do contrato",
          value: this.planInCart ? this.planInCart.renewal_date : "",
          minToday: false,
          maxToday: false,
          readonly: false,
          disabled: this.trialSelected,
          visible: true,
          key: "renewal_date",
        },
        {
          label: "Primeira cobrança",
          info: "Data em que será gerada a primeira cobrança",
          value: this.planInCart ? this.planInCart.init_billing : "",
          maxToday: false,
          readonly: true,
          visible: false,
          key: "init_billing",
        },
        {
          label: "Previsão de próxima cobrança",
          value: this.planInCart ? this.planInCart.next_billing : "",
          maxToday: false,
          readonly: false,
          visible: false,
          key: "next_billing",
        },
        {
          label: "Abertura da transação",
          value: this.planInCart ? this.planInCart.init_transaction : "",
          maxToday: false,
          readonly: false,
          visible: true,
          disabled: this.trialSelected,
          key: "init_transaction",
        },
        {
          label: "Encerramento da transação",
          info: "A data em que esta transação será encerrada",
          value: this.planInCart ? this.planInCart.end_transaction : "",
          maxToday: false,
          readonly: true,
          visible: true,
          key: "end_transaction",
        },
        {
          label: "Cancelamento do plano",
          value: this.planInCart ? this.planInCart.end_date : "",
          maxToday: false,
          readonly: false,
          caches: false,
          visible: false,
          disabled: true,
          key: "end_date",
        },
      ].filter((item) => item.visible);
    },

    currentNote: {
      get() {
        return this.planInCart ? this.planInCart.notes : "";
      },
      set(new_note) {
        this.updatePlanInCart({ key: "notes", value: new_note });
      },
    },

    trialSelected() {
      return this.planInCart
        ? this.planInCart.subscription_type === SUBSCRIPTIONS.TRIAL
        : false;
    },

    flexSelected() {
      return this.planInCart
        ? this.planInCart.subscription_type === SUBSCRIPTIONS.FLEX
        : false;
    },
  },

  watch: {
    planInCart(new_value, old_value) {
      const changedInitTransaction =
        old_value.init_transaction !== new_value.init_transaction;

      const hasInitTransaction = this.planInCart.init_transaction;

      const changedSubscriptionType =
        old_value.subscription_type !== new_value.subscription_type;

      if (hasInitTransaction && changedInitTransaction) {
        this.updateTransactionDates(new_value);
      } else if (changedSubscriptionType) {
        if (new_value.subscription_type === SUBSCRIPTIONS.TRIAL) {
          this.resetTransactionDates();
        } else {
          this.updateTransactionDates(new_value);
        }
      }

      this.$emit("end_trial", this.planInCart.end_trial);
    },

    "planInCart.end_trial"() {
      if (
        this.planInCart.end_trial &&
        dateIsAfterAnother(
          this.planInCart.end_trial,
          this.planInCart.init_transaction
        )
      ) {
        const initBilling = formatDateForDatabase(
          addDays(this.planInCart.end_trial, 1)
        );
        const renewalDate = formatDateForDatabase(addYears(initBilling, 1));

        this.updatePlanInCart({
          key: "init_billing",
          value: initBilling,
        });

        this.updatePlanInCart({
          key: "init_transaction",
          value: initBilling,
        });

        this.updatePlanInCart({
          key: "renewal_date",
          value: renewalDate,
        });
      }
    },

    async "planInCart.subscription_type"() {
      await this.updateTransactionDates(this.planInCart);

      if (this.planInCart.subscription_type) {
        const { subscription_type, init_transaction } = this.planInCart;

        const endTransaction = setEndTransactionByPeriod(
          subscription_type,
          init_transaction
        );

        const initBilling = init_transaction;

        this.updatePlanInCart({
          key: "end_transaction",
          value: endTransaction,
        });

        this.updatePlanInCart({
          key: "init_billing",
          value: initBilling,
        });

        this.updatePlanInCart({
          key: "init_transaction",
          value: initBilling,
        });
      }
    },

    "planInCart.end_transaction"() {
      if (
        !this.planInCart.end_transaction &&
        this.planInCart.subscription_type &&
        this.planInCart.init_transaction
      ) {
        const { subscription_type, init_transaction } = this.planInCart;

        const endTransaction = setEndTransactionByPeriod(
          subscription_type,
          init_transaction
        );

        this.updatePlanInCart({
          key: "end_transaction",
          value: endTransaction,
        });
      }
    },

    "planInCart.init_transaction"() {
      if (this.planInCart.subscription_type) {
        const { subscription_type, init_transaction } = this.planInCart;

        const endTransaction = setEndTransactionByPeriod(
          subscription_type,
          init_transaction
        );

        const initBilling = init_transaction;

        this.updatePlanInCart({
          key: "end_transaction",
          value: endTransaction,
        });

        this.updatePlanInCart({
          key: "init_billing",
          value: initBilling,
        });

        this.updatePlanInCart({
          key: "init_transaction",
          value: initBilling,
        });
      }
    },

    "planInCart.renewal_date"() {
      if (this.planInCart.renewal_date) {
        this.updatePlanInCart({
          key: "renewal_date",
          value: this.planInCart.renewal_date,
        });
      }
    },
  },

  methods: {
    ...mapMutations(["updatePlanInCart", "resetTransactionDates"]),

    formatDate,

    updateEndTrial(date) {
      this.updatePlanInCart({
        key: "init_transaction",
        value: date,
      });
    },

    setNote(note) {
      this.updatePlanInCart({ key: "notes", value: note });
    },

    setEndTransactionByPeriod(date = null) {
      if (this.trialSelected) {
        return null;
      }

      date = !date ? moment(this.today) : moment(date);

      const end_transaction_by_period = this.flexSelected
        ? date.endOf("month")
        : date.add(1, "year").subtract(1, "days");

      return formatDateForDatabase(end_transaction_by_period);
    },

    updateTransactionDates(updatedData) {
      const { init_transaction } = updatedData;
      let renewalDate = "";
      if (updatedData.status === "ACTIVE") {
        renewalDate = formatDateForDatabase(addYears(init_transaction, 1));
      }
      const endTransactionDate =
        this.setEndTransactionByPeriod(init_transaction);

      this.updatePlanInCart({
        key: "end_transaction",
        value: endTransactionDate,
      });

      this.updatePlanInCart({
        key: "init_transaction",
        value: init_transaction,
      });

      this.updatePlanInCart({
        key: "renewal_date",
        value: renewalDate,
      });

      this.$emit("init-transaction", init_transaction);
      this.$emit("end-transaction", endTransactionDate);
      this.$emit("renewal-date", renewalDate);
    },

    updateField(key, value) {
      if (key !== "end_transaction") {
        if (key === "init_transaction") {
          this.updateTransactionDates(this.planInCart);
        }
        this.updatePlanInCart({ key: key, value: value });
      }
    },

    updateDate(item, date) {
      this.updateField(item.key, date);
      const key = item.key.replace("_", "-");
      this.$emit(key, date);
    },
  },

  beforeMount() {
    this.updateTransactionDates(this.planInCart);
  },
};
</script>
