<template>
  <div>
    <!-- TODO: UNIFICAR A VISUALIZAÇÃO DE PLANOS/SERVIÇOS -->
    <Alert
      v-if="showLowerLicensesNumber"
      color="error"
      dense
      :text="false"
      dark
      class="font-weight-bold mx-0"
    >
      O número de licenças informado é inferior ao valor mínimo permitido de
      {{ licenses }} licenças.
    </Alert>

    <CheckoutPlansTable @update="verifyMinLicensesNumber" />

    <v-card outlined class="mt-4" v-if="isConecta && !isSelfCheckout">
      <div v-if="hasServicesInCart || showServices">
        <v-card-title class="text-body-1 font-weight-medium">
          <v-row no-gutters class="ma-0 pa-0" align="center">
            <v-col>Serviços</v-col>
            <v-col
              :class="$vuetify.breakpoint.smAndUp ? 'd-flex justify-end' : ''"
            >
              <v-btn
                color="accent"
                class="text-none text-subtitle-2 elevation-0"
                @click="showServices = !showServices"
              >
                <v-icon
                  v-text="showServices ? 'mdi-eye-off' : 'mdi-eye'"
                  left
                />
                {{
                  `${showServices ? "Ocultar" : "Mostrar"} serviços disponíveis`
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <ServicePurchase
          :show-services="showServices"
          :outlined="false"
          @cart="$emit('service-cart', $event)"
        />
      </div>

      <v-card-text v-else class="text-center">
        <p>Nenhum serviço adicionado para esta compra.</p>
        <v-btn
          color="accent"
          class="text-none text-subtitle-2 elevation-0"
          @click="showServices = true"
        >
          <v-icon v-text="'mdi-plus'" left />
          Adicionar serviço
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import CheckoutPlansTable from "@/components/billing/CheckoutPlansTable.vue";
import ServicePurchase from "@/components/purchase/ServicePurchase.vue";
import { MIN_LICENSES } from "@/helpers/variables/backendConstants.js";
import { mapGetters } from "vuex";

export default {
  name: "CheckoutItems",

  components: { ServicePurchase, CheckoutPlansTable },

  data() {
    return {
      showServices: false,
      showLowerLicensesNumber: false,
    };
  },

  computed: {
    ...mapGetters([
      "hasServicesInCart",
      "isSelfCheckout",
      "isConecta",
      "usersNumber",
      "isSelfCheckout",
      "selectedUsersNumber",
      "hiringType",
    ]),

    licenses() {
      const companyLicenses = this.isSelfCheckout
        ? this.usersNumber
        : this.selectedUsersNumber;

      if (this.hiringType == "hiring" && companyLicenses < MIN_LICENSES) {
        return MIN_LICENSES;
      }
      return companyLicenses;
    },
  },

  methods: {
    verifyMinLicensesNumber(currentNumber) {
      if (currentNumber < this.licenses) {
        this.showLowerLicensesNumber = true;
      } else {
        this.showLowerLicensesNumber = false;
      }
    },
  },
};
</script>
