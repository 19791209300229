<template>
  <v-card
    flat
    tile
    height="300"
    :max-width="listStyle ? 500 : 300"
    :class="`mt-4 ${center ? 'mx-auto' : ''} ${
      hasMoreThanOnePlan ? 'mb-8' : ''
    } elevation-0`"
  >
    <v-card-subtitle class="text-subtitle-2 text-center text-uppercase">
      <span v-if="isNewPlan"> {{ $t("selfCheckout.newPlan") }}</span>
      <span v-else>{{
        hasMoreThanOnePlan
          ? `${$t("common.currentPlans")} (${currentPlansSize})`
          : $t("common.currentPlan")
      }}</span>
    </v-card-subtitle>
    <v-carousel
      v-model="carousel"
      :show-arrows="false"
      hide-delimiter-background
      :hide-delimiters="isNewPlan || !hasMoreThanOnePlan"
      :light="!$vuetify.theme.dark"
      continuous
      :cycle="hasMoreThanOnePlan && !isNewPlan"
      :interval="5000"
      width="100%"
      height="290"
      class="elevation-0"
    >
      <v-carousel-item v-for="(plan, i) in plansToShow" :key="i">
        <v-card
          outlined
          :style="`${newPlan ? 'border: 1px solid orange;' : ''}`"
        >
          <v-toolbar
            dense
            flat
            :color="newPlan ? 'accent lighten-2' : 'grey lighten-3'"
            :class="`mb-4 ma-0`"
          >
            <v-toolbar-title class="text-body-1 font-weight-medium my-2">
              {{ plan.plan?.name || selectedPlanName }}
            </v-toolbar-title>
          </v-toolbar>

          <v-card-title class="font-weight-bold align-end text-h5 my-0 py-0">
            <span
              v-if="
                [plan.subscription_type, plan.status].includes(
                  SUBSCRIPTIONS.TRIAL
                ) || trial
              "
              class="text-uppercase"
            >
              {{ $t("selfCheckout.trialPeriod") }}
            </span>
            <span v-else>
              {{ formatMoney(getTotalPrice(plan)) || "0,00"
              }}<span
                class="text-body-2 pb-1 ml-1"
                v-if="plan.subscription_type !== SUBSCRIPTIONS.FREE"
                >/
                {{
                  !!plan.subscription_type
                    ? $t(plan.subscription_type)
                    : $t(plan.type)
                }}</span
              >
            </span>
          </v-card-title>
          <v-card-subtitle
            v-if="
              (plan.subscription_type === 'ANNUAL' || plan.type === 'ANNUAL') &&
              plan.discountText
            "
            class="green--text caption mx-4 font-weight-medium px-0 my-0 py-0"
          >
            {{
              `${$t("billing.discountOf")} ${plan.discountText}% ${$t(
                "billing.fromOriginalPrice"
              )}`
            }}
          </v-card-subtitle>
          <v-card-subtitle
            class="text-body-2 font-weight-medium py-0 mb-4 mt-2"
          >
            <!-- QUANDO PLANO FOR NOVO, EXIBIR A DATA EM QUE ELE COMEÇARÁ A SER PAGO -->
            <span v-if="nextBilling">
              {{ `${$t("common.from")} ${formatDateFullMonth(nextBilling)}` }}
            </span>
            <!-- SE O PLANO ATUAL FOR TRIAL. EXIBIR A DATA EM QUE ELE IRÁ ENCERRAR -->
            <span
              v-else-if="
                (plan.subscription_type === SUBSCRIPTIONS.TRIAL && !newPlan) ||
                trial
              "
            >
              {{
                `${$t("common.until")} ${formatDateFullMonth(plan.end_trial)}`
              }}
            </span>
            <!-- QUANDO UM PLANO ATUAL FOR ATIVO, EXIBIR A DATA EM QUE ELE COMEÇOU A SER PAGO -->
            <span v-else>
              {{
                `${$t("common.since")} ${formatDateFullMonth(
                  plan.init_billing
                )}`
              }}
            </span>
          </v-card-subtitle>
          <v-divider />
          <v-card-subtitle class="mt-5 text-body-2 font-weight-medium py-0">
            {{ $t("common.includes") }}
          </v-card-subtitle>
          <v-list v-if="listStyle" dense>
            <v-list-item
              v-for="(product, index) in getListOfProducts(plan)"
              :key="index"
              class="py-0 my-0"
            >
              <v-list-item-icon class="px-0 ml-0 mr-2">
                <v-icon v-text="'mdi-check-circle'" dense color="success" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ product }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions v-else class="px-4 mb-2 align-start">
            <span
              v-for="(product, index) in getListOfProducts(plan)"
              :key="index"
              class="mr-1"
            >
              <Logo
                height="35"
                width="35"
                :product="product"
                products
                no-margins
              />
            </span>
          </v-card-actions>
        </v-card>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import {
  formatMoney,
  formatDateFullMonth,
  formatDateForDatabase,
  formatDate,
} from "@/helpers/services/utils";
import { getTotalPlanPrice } from "@/helpers/services/billing";
import {
  translatePeriods,
  productNames,
} from "@/helpers/variables/translateString";
import { SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";

export default {
  name: "ComparativePlan",

  props: {
    new: { type: Object, default: () => {} },
    center: { type: Boolean, default: true },
    nextBilling: { type: String, default: "" },
    trial: { type: Boolean, default: false },
    listStyle: { type: Boolean, default: false },
  },
  data() {
    return {
      carousel: 0,
      translatePeriods,
      productNames,
      getTotalPlanPrice,
      SUBSCRIPTIONS,
    };
  },
  computed: {
    ...mapGetters([
      "impactedCompanyPlans",
      "currentSelectedPlan",
      "selectedPlanName",
      "usersNumber",
      "selectedResellerClient",
      "isSelfCheckout",
    ]),

    isNewPlan() {
      return this.newPlan ? true : false;
    },

    newPlan() {
      return this.new;
    },

    plansToShow() {
      if (this.newPlan) {
        return [this.newPlan];
      }
      return this.impactedCompanyPlans;
    },

    hasMoreThanOnePlan() {
      return this.impactedCompanyPlans.length > 1;
    },

    currentPlansSize() {
      return this.impactedCompanyPlans.length;
    },
  },
  methods: {
    formatMoney,
    formatDate,
    formatDateFullMonth,
    formatDateForDatabase,

    getTotalPrice(plan) {
      if (plan.total_price) {
        return plan.total_price;
      }
      const isAnnualPlan =
        plan.subscription_type === "ANNUAL" || plan.type === "ANNUAL";

      let licenses = isAnnualPlan
        ? plan.max_licenses
        : this.isSelfCheckout
        ? this.usersNumber
        : this.selectedResellerClient.users_number;

      return this.getTotalPlanPrice(
        licenses,
        plan.subscription_type,
        plan.discount,
        plan.plan?.price
      );
    },

    getListOfProducts(plan) {
      if (!this.listStyle) {
        return this.newPlan
          ? this.newPlan.plan.products.map((item) => item.name)
          : plan.plan.products.map((item) => item.name);
      } else {
        return this.newPlan
          ? this.newPlan.plan.products.map((item) => productNames[item]).sort()
          : plan.plan.products.map((item) => productNames[item.name]).sort();
      }
    },
  },
};
</script>
