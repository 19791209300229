<template>
  <v-container :class="`${$vuetify.breakpoint.lgAndUp ? 'px-6' : 'px-4'} py-6`">
    <v-card
      class="fill-height mx-auto elevation-0 d-flex flex-column"
      outlined
      width="100vw"
      height="88vh"
    >
      <!-- HEADER -->
      <v-row
        fluid
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
        class="px-5 py-2 ma-0 d-flex align-center"
      >
        <div class="d-flex align-center">
          <v-btn class="breadcrumb-link" icon @click="goBack">
            <v-icon v-text="'mdi-arrow-left'" />
          </v-btn>

          <v-row class="ma-0 pa-0" max-width="500" v-if="loading">
            <v-col
              class="ma-0 pa-0 d-flex align-center"
              v-for="i in 3"
              :key="i"
            >
              <v-skeleton-loader type="chip"></v-skeleton-loader>
              <v-icon v-if="i < 3" v-text="'mdi-chevron-right'" />
            </v-col>
          </v-row>

          <v-breadcrumbs
            v-else
            :items="breadcrumbs"
            class="pa-0 py-1 mx-3 breadcrumbs breadcrumb-link"
            large
          >
            <template v-slot:divider>
              <v-icon v-text="'mdi-chevron-right'" />
            </template>

            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                v-if="item.text === 'Comprar' && selectedPlan"
                @click="goBackToPlans"
                :disabled="item.disabled"
                :exact="steps > 1"
              >
                <span
                  :class="`${
                    item.text === 'Comprar' && selectedPlan ? 'go-to-step' : ''
                  }`"
                  >{{ item.text }}</span
                >
              </v-breadcrumbs-item>
              <v-breadcrumbs-item
                v-else
                :to="item.to"
                :disabled="item.disabled"
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </div>
      </v-row>

      <Alert
        v-if="resellerClientDoNotHaveCnpj"
        color="error"
        class="mx-4 mt-4"
        :text="false"
        dark
      >
        Esta empresa <b>não possui CNPJ cadastrado</b>. Por favor, após
        selecionar o plano, preencha o CNPJ onde for requisitado.
      </Alert>

      <!-- BODY -->
      <div class="overflow-y-auto fill-height">
        <SimpleDialog
          id="discount_info"
          title="Descontos para Conecta Partners"
          :show="showDiscountInfoDialog"
          @close="showDiscountInfoDialog = false"
          readOnly
        >
          <template v-slot:body>
            <p class="mt-4 mb-0 pb-0">
              A partir de 21/11/2021,
              <span class="font-weight-medium"
                >todos os Conecta Partners independente do tipo de
                parceria</span
              >
              passam a possuir 20% de desconto nos planos ofertados por eles,
              isto é, todos os clientes destes parceiros receberão por
              padrão,<span v-if="isConecta"> pelo menos</span> 20% de desconto
              no preço final dos planos<span v-if="isConecta"
                >, podendo este desconto ser ampliado com um acréscimo de no
                máximo, 35% a mais de desconto</span
              >.
            </p>
          </template>
        </SimpleDialog>

        <div class="my-6 mr-4 mt-0">
          <v-stepper
            v-model="steps"
            flat
            :class="`${
              $vuetify.breakpoint.mobile ? 'px-0' : ''
            } elevation-0 justify-center d-block py-0`"
          >
            <v-stepper-header
              class="steps elevation-0 mx-auto font-weight-medium"
            >
              <v-stepper-step
                step="1"
                :complete="steps > 1"
                @click="goBackToPlans"
                :class="{ 'go-to-step': steps > 1 }"
                :disabled="!isConecta"
              >
                Tipo de contratação
              </v-stepper-step>

              <v-stepper-step
                step="2"
                :complete="steps > 2"
                @click="goToStep(2)"
                :class="{ 'go-to-step': steps > 2 }"
              >
                Escolha o plano
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
                :complete="steps > 3"
                @click="goToStep(3)"
                :class="{ 'go-to-step': steps === 3 }"
              >
                Escolha a periodicidade
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4" :complete="steps > 4">
                Configurações do pedido
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items class="pt-0">
              <v-stepper-content step="1" class="pt-1 text-center">
                <v-card height="500" class="d-flex align-center justify-center">
                  <v-card-text class="d-flex align-center justify-center">
                    <v-card
                      max-width="500"
                      min-width="400"
                      min-height="200"
                      outlined
                      @click="changeHiringType('hiring')"
                    >
                      <v-card-title
                        >Usar limite mínimo de 15 licenças</v-card-title
                      >
                      <v-card-text class="text-left">
                        <p>Quando usar:</p>

                        <li>Contratação de novos clientes</li>
                        <li>Upgrade ou downgrade</li>
                        <li>Renovação de planos já com essa regra</li>
                      </v-card-text>
                      <br />
                    </v-card>

                    <v-col cols="2"><strong>OU</strong></v-col>

                    <v-card
                      max-width="500"
                      min-width="400"
                      min-height="200"
                      outlined
                      @click="changeHiringType('renewal')"
                    >
                      <v-card-title
                        >Ignorar limite mínimo de licenças</v-card-title
                      >
                      <v-card-text class="text-left">
                        <p>Quando usar:</p>

                        <li>Renovação de clientes sem essa regra</li>
                        <p class="mt-2">
                          Usado principalmente para cliente antigo que mantêm o
                          plano, mas ajusta preço ou número de licenças.
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2" class="pt-1 text-center">
                <PlanSelector @selected="selectPlan" />
              </v-stepper-content>

              <!--  PASSO 2: LISTA DE OPÇÕES PARA O PLANO SELECIONADO -->
              <v-stepper-content step="3" class="pt-1">
                <v-row class="mt-8" v-if="applyMinLicensesRule">
                  <Alert :prominent="false" color="warning" dense :width="800">
                    {{ $t("selfCheckout.minLicensesInfo") }}
                  </Alert>
                </v-row>

                <SubscriptionPlans
                  v-if="currentClient && steps === 3"
                  planId
                  @select="selectSubscriptionType"
                />
              </v-stepper-content>

              <v-stepper-content step="4" class="mt-0">
                <v-card tile flat :disabled="loadingPurchase">
                  <CompanyInfo />

                  <OrderSettings
                    :disabled="loadingPurchase"
                    @end-trial="
                      updatePlanInCart({ key: 'end_trial', value: $event })
                    "
                    @init-transaction="
                      updatePlanInCart({
                        key: 'init_transaction',
                        value: $event,
                      })
                    "
                    @end-transaction="
                      updatePlanInCart({
                        key: 'end_transaction',
                        value: $event,
                      })
                    "
                    @renewal-date="
                      updatePlanInCart({
                        key: 'renewal_date',
                        value: $event,
                      })
                    "
                  />

                  <CheckoutItems />

                  <v-card
                    outlined
                    class="py-2 px-0 mt-6"
                    v-if="selectedIsAnnual && isConecta"
                  >
                    <v-card-title
                      class="pt-2 text-body-1 font-weight-medium grey--text text--darken-3"
                      v-text="'Configurações do pedido'"
                    />
                    <v-divider />
                    <v-card-actions>
                      <v-row>
                        <v-col class="pb-1">
                          <!-- CRIAR TRANSAÇÃO -->
                          <v-row class="mx-0 mt-2 mb-0">
                            <v-col class="py-0 pb-2 d-flex align-center">
                              <span class="font-weight-medium mr-4"
                                >Gerar nova transação para esta edição?
                              </span>
                              <v-switch
                                style="max-height: 30px"
                                class="my-0 py-0"
                                v-model="createTransaction"
                                inset
                                :label="createTransaction ? 'SIM' : 'NÃO'"
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </div>

      <v-scroll-y-reverse-transition>
        <CheckoutFooter
          v-if="steps > 3"
          :loading="loadingPurchase"
          :disabledAction="
            canNotAddPlan ||
            minValueNotReached ||
            !minLicensesNumberUnreached ||
            (!selectedCNPJ && !(isConecta && isEditingTrial))
          "
          @purchase="verifyContractedProducts"
          @cancel="steps = 3"
        />
      </v-scroll-y-reverse-transition>
    </v-card>

    <HeaderDialog
      v-if="impactedCompanyPlans"
      subtitle="Confirmar novo plano"
      :title="selectedPlanName"
      width="800"
      :show="showConfirmNewPlan"
      :loading="loadingPurchase"
      action-text="Contratar plano"
      close-text="Cancelar"
      @action="hirePlan"
      @close="showConfirmNewPlan = false"
    >
      <template v-slot:body>
        <p class="ma-0">
          A empresa <b>{{ companyName }}</b> já possui um ou mais produtos do
          plano que você deseja adquirir. Ao prosseguir, você concorda que o
          plano atual seja desativado e o novo contratado.
        </p>

        <v-card-text class="px-center pt-0">
          <v-row no-gutters dense class="align-center ma-0 pa-0 pb-8">
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 5" class="pa-0">
              <ComparativePlan />
            </v-col>

            <v-col
              v-if="!$vuetify.breakpoint.xs"
              :cols="2"
              class="pa-0 d-flex justify-center"
            >
              <v-icon
                class="mx-auto"
                v-text="
                  $vuetify.breakpoint.xs
                    ? 'mdi-arrow-down-thick'
                    : 'mdi-arrow-right-thick'
                "
                :size="$vuetify.breakpoint.xs ? 35 : 65"
                right
              />
            </v-col>

            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 5" class="py-0">
              <ComparativePlan
                v-if="hasPlanInCart"
                :new="planInCart"
                :nextBilling="planInCart.init_billing"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </template>
    </HeaderDialog>

    <Snackbar
      :show="showSnackBarError"
      color="error"
      :message="error_message"
      @close="showSnackBarError = false"
      timeout="6000"
    />

    <ConfirmationDialog
      actionText="Ignorar regra mínima de licenças"
      :show="confirmNoMinLicensesRuleDialog"
      btnActionText="Entendo, quero ignorar a regra"
      width="700"
      @close="closeDialog"
      @ok="goToNextStep"
      dense
      message="
       Esse cliente esta atualmente sob a regra de limite mínimo de licenças, nesse caso é recomendado que ele permaneça com a regra ativa. 
       Deseja mesmo ignorar a regra de limite mínimo de licenças para esse cliente?
      "
    />
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import * as moment from "moment";

import {
  getHirePlanPayload,
  isAnnual,
  isTrial,
  setEndTrialDate,
  setInitDates,
  setEndTransactionByPeriod,
} from "@/helpers/services/companyPlan";

import { subscriptionPlans } from "@/helpers/variables/subscriptionPlans.js";
import {
  formatDate,
  moveScreenToUp,
  formatDateForDatabase,
} from "@/helpers/services/utils";
import { planPeriodic } from "@/helpers/variables/translateString";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { planOrder } from "@/helpers/variables/clients";
import {
  STATUS,
  SUBSCRIPTIONS,
  BASIC_SKU,
  MIN_LICENSES,
} from "@/helpers/variables/backendConstants.js";

import PlanSelector from "@/components/billing/PlanSelector.vue";
import CheckoutItems from "@/components/billing/checkout/CheckoutItems.vue";
import CompanyInfo from "@/components/billing/checkout/CompanyInfo";
import OrderSettings from "@/components/billing/checkout/OrderSettings";
import CheckoutFooter from "@/components/billing/checkout/CheckoutFooter";
import ComparativePlan from "@/components/purchase/ComparativePlan";
import SubscriptionPlans from "@/components/purchase/SubscriptionPlans.vue";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";

export default {
  name: "NewPlan",

  components: {
    PlanSelector,
    SubscriptionPlans,
    CheckoutItems,
    CompanyInfo,
    OrderSettings,
    CheckoutFooter,
    ComparativePlan,
    ConfirmationDialog,
  },

  data() {
    return {
      planOrder,
      subscriptionPlans,
      STATUS,
      SUBSCRIPTIONS,
      BASIC_SKU,
      planPeriodic,
      selected: null,
      orderDetails: planOrder,
      error_message: "",
      showDiscountInfoDialog: false,
      showConfirmNewPlan: false,
      steps: 1,
      loadingPurchase: false,
      loading: false,
      client: null,
      main_domain: null,
      breadcrumbs: [
        {
          text: "Clientes",
          disabled: false,
          to: "/reseller/clients",
        },
        {
          text: "",
          disabled: false,
          to: "",
        },
        {
          text: "Comprar",
          disabled: false,
          to: "",
        },
      ],
      showSnackBarError: false,
      today: moment().format("YYYY-MM-DD"),
      confirmNoMinLicensesRuleDialog: false,
    };
  },

  computed: {
    ...mapGetters([
      "isConecta",
      "company",
      "selectedResellerClient",
      "currentSelectedPlan",
      "clientHasThisPlan",
      "impactedCompanyPlans",
      "selectedPlanName",
      "getSelectedResellerClientError",
      "resellerClientDoNotHaveCnpj",
      "mainDomain",
      "planInCart",
      "hasPlanInCart",
      "servicesCart",
      "nearestEndTrialActivePlans",
      "nearestEndTrialDate",
      "impactedActiveCompanyPlans",
      "impactedCompanyPlansInTrial",
      "planCartTotal",
      "hiringType",
    ]),

    applyMinLicensesRule() {
      return this.hiringType == "hiring" && this.usersNumber < MIN_LICENSES;
    },

    createTransaction: {
      get() {
        return this.planInCart ? this.planInCart.create_transaction : true;
      },
      set(value) {
        this.updatePlanInCart({ key: "create_transaction", value: value });
      },
    },

    selectedIsAnnual() {
      return isAnnual(this.planInCart.subscription_type);
    },

    hasBasicFreeInTrial() {
      return this.impactedCompanyPlansInTrial.find((companyPlan) => {
        const { plan, status } = companyPlan;
        return this.BASIC_SKU == plan.sku && status == STATUS.TRIAL;
      });
    },

    companyName() {
      return this.selectedResellerClient
        ? this.selectedResellerClient.name
        : "";
    },

    selectedCNPJ() {
      return this.selectedResellerClient?.cnpj;
    },

    minValueNotReached() {
      if (!isTrial(this.planInCart.subscription_type)) {
        return (
          this.planCartTotal < 40 &&
          this.selectedResellerClient &&
          this.selectedResellerClient.reseller_company.type === "DIRECT"
        );
      }
      return false;
    },

    minLicensesNumberUnreached() {
      let licenses = this.usersNumber;

      if (this.hiringType == "hiring") {
        licenses =
          this.usersNumber < MIN_LICENSES ? MIN_LICENSES : this.usersNumber;
      }

      const validLicenses = this.selectedIsAnnual
        ? this.planInCart.max_licenses >= licenses
        : licenses == this.planInCart.licenses;

      return validLicenses;
    },

    canSuggestInitTransaction() {
      return moment(this.planInCart.end_trial).isAfter(
        moment(this.planInCart.init_transaction)
      );
    },

    canNotAddPlan() {
      if (this.planInCart.subscription_type === SUBSCRIPTIONS.TRIAL) {
        return false;
      }

      return (
        this.planInCart.init_transaction === null ||
        this.planInCart.end_transaction === null ||
        !this.minLicensesNumberUnreached
      );
    },

    selectedPlan: {
      get() {
        return this.selected;
      },
      set(new_selection) {
        this.selected = new_selection;
      },
    },

    clientKey() {
      return this.currentClient.key;
    },

    clientMainDomain() {
      return this.currentClient.main_domain;
    },

    currentClient() {
      if (this.client) {
        return this.client;
      }
      return false;
    },

    usersNumber() {
      return this.selectedResellerClient
        ? this.selectedResellerClient.users_number
        : 0;
    },

    isEditingTrial() {
      if (this.clientHasThisPlan) {
        const { subscription_type } = this.clientHasThisPlan;

        return (
          subscription_type == SUBSCRIPTIONS.TRIAL &&
          this.planInCart.subscription_type == SUBSCRIPTIONS.TRIAL
        );
      }

      return false;
    },
  },

  watch: {
    canSuggestInitTransaction() {
      if (this.canSuggestInitTransaction) {
        this.setNewInitTransaction();
      }
    },

    "planInCart.init_transaction"() {
      if (this.steps !== 2) {
        const { subscription_type, init_transaction } = this.planInCart;

        const endTransaction = setEndTransactionByPeriod(
          subscription_type,
          init_transaction
        );

        const initBilling = init_transaction;

        this.updatePlanInCart({
          key: "end_transaction",
          value: endTransaction,
        });

        this.updatePlanInCart({
          key: "init_billing",
          value: initBilling,
        });

        this.updatePlanInCart({
          key: "init_transaction",
          value: initBilling,
        });
      }
    },

    steps() {
      if (this.steps === 1) {
        this.setSelectedPlan("");
        this.clearServiceCart();
        this.resetPlanInCart();
      } else if (this.steps === 2) {
        this.clearServiceCart();
        this.resetOrderDates();
        this.setMaxLicenseNumber(this.planInCart.licenses);
      }
    },

    plans() {
      if (this.$route.query.plan) {
        this.selectPlan(this.$route.query.plan);
      }
    },

    async "$route.fullPath"() {
      await this.startLoadClient()
        .then(() => {
          if (this.selectedResellerClient && this.resellerClientDoNotHaveCnpj) {
            this.$router.push({ name: "Clients" });
          }
        })
        .catch(() =>
          this.$router.push({
            path: "/reseller/client",
            query: { domain: this.main_domain, key: this.$route.query.key },
          })
        );
    },

    "$route.query.plan"() {
      if (this.$route.query.plan) {
        this.selectPlan(this.$route.query.plan);
      }
    },
  },

  methods: {
    ...mapActions([
      "getSelectedResellerClient",
      "getPlans",
      "getContractedPlans",
      "updateHiredPlan",
      "hireNewPlan",
    ]),

    ...mapMutations([
      "setSelectedPlan",
      "setLoading",
      "setClientView",
      "setIsHomePage",
      "setSnackBar",
      "setPlanCart",
      "updatePlanInCart",
      "clearCart",
      "resetPlanInCart",
      "setMaxLicenseNumber",
      "clearServiceCart",
      "setHiringType",
    ]),

    formatDate,

    formatDateForDatabase,

    changeHiringType(value) {
      const current_plan = this.selectedResellerClient.current_plan || {};

      if (value == "renewal" && current_plan.min_licenses) {
        this.confirmNoMinLicensesRuleDialog = true;
        return;
      }

      this.setHiringType(value);
      this.steps++;
    },

    closeDialog() {
      this.confirmNoMinLicensesRuleDialog = false;
    },

    goToNextStep() {
      this.setHiringType("renewal");
      this.closeDialog();
      this.steps++;
    },

    setErrorMessage(code) {
      const error_code = "unknown";
      this.error_message = errorMessages[code] || errorMessages[error_code];
    },

    verifyContractedProducts() {
      if (this.impactedCompanyPlans.length > 0) {
        this.showConfirmNewPlan = true;
      } else {
        this.hirePlan();
      }
    },

    goBackToPlans() {
      if (this.breadcrumbs[this.breadcrumbs.length - 1].text !== "Comprar") {
        this.breadcrumbs.pop();
      }
      // Replace para remover possíveis parâmetros de algum plano selecionado anteriormente
      if (this.$route.query.plan) {
        this.$router.replace({
          path: "/reseller/new-plan",
          query: { domain: this.main_domain, key: this.clientKey },
        });
      }
      if (this.isConecta) this.steps = 1;
      this.orderDetails = Object.assign({}, planOrder);
    },

    goToStep(step_to_go) {
      if (this.steps > step_to_go) {
        this.steps = step_to_go;
      }
    },

    /**
     * Set the order details based on the details of the step 2
     * @param Object The plan details of step 2
     */
    setOrderDetails(plan) {
      const keys = [
        "plan_key",
        "name",
        "subscription_type",
        "status",
        "discount",
        "licenses",
        "max_licenses",
        "price",
        "notes",
        "products",
      ];

      keys.forEach((key) => {
        this.updatePlanInCart({ key: key, value: plan[key] });
      });
    },

    /* Set the end_trial and billing dates according with companyPlan situation */
    setCompanyPlanDates(billingDetails) {
      let companyPlan = { ...this.orderDetails, ...billingDetails };

      this.setPlanCart(companyPlan);

      const hasActiveTrialPlans = this.impactedCompanyPlansInTrial.length > 0;

      const hasActivePurchasedPlans =
        this.impactedActiveCompanyPlans.length > 0;

      let nearestEndTrialDate = "";

      if (hasActivePurchasedPlans) {
        nearestEndTrialDate = this.nearestEndTrialActivePlans;
      } else {
        nearestEndTrialDate = this.nearestEndTrialDate;
      }

      const endTrial = setEndTrialDate(
        companyPlan,
        nearestEndTrialDate,
        hasActiveTrialPlans,
        hasActivePurchasedPlans,
        this.clientHasThisPlan
      );

      companyPlan.end_trial = this.hasBasicFreeInTrial
        ? formatDateForDatabase(this.today)
        : endTrial;

      companyPlan = setInitDates(companyPlan, this.clientHasThisPlan);

      this.setPlanCart(companyPlan);

      const { users_number } = this.selectedResellerClient;
      const companyLicenses =
        this.hiringType == "hiring" && users_number < MIN_LICENSES
          ? MIN_LICENSES
          : users_number;

      this.updatePlanInCart({ key: "licenses", value: companyLicenses });
    },

    selectSubscriptionType(plan) {
      this.setOrderDetails(plan);
      this.setCompanyPlanDates(plan);
      this.steps = 4;
    },

    // Método executado passando do passo 1 para o 2 (Escolha do plano -> escolha das periodicidades)
    selectPlan(key) {
      this.setSelectedPlan(key); // Adiciona a chave do plano em questão

      if (this.clientHasThisPlan) {
        // O plano já existe
        this.selectedPlan = Object.assign({}, this.clientHasThisPlan);
      } else {
        // O plano ainda não existe
        this.selectedPlan = Object.assign({}, this.currentSelectedPlan);
      }

      this.orderDetails.plan = this.currentSelectedPlan;
      this.orderDetails.products = this.selectedPlan.products
        ? this.selectedPlan.products.map((item) => item.name)
        : [];

      this.steps = 3;

      this.breadcrumbs.push({
        text: this.selectedPlanName,
      });
    },

    async startLoadClient() {
      this.setLoading(true);
      if (this.$route.query.key !== this.selectedResellerClient?.key) {
        await this.getSelectedResellerClient(this.$route.query.key).then(() => {
          if (this.getSelectedResellerClientError) {
            this.goBackToResellerClients();
            this.setSnackBar({
              show: true,
              message: "Cliente não existe.",
              color: "error",
            });
          }
        });
      }
      this.setClientView(false);
      this.setIsHomePage(false);
      this.loading = true;
      await this.getPlans();
      this.main_domain = this.selectedResellerClient.main_domain;
      this.client = this.selectedResellerClient;
      if (this.client) {
        this.breadcrumbs[1].to = {
          path: "/reseller/client",
          query: { domain: this.main_domain, key: this.$route.query.key },
        };
        this.breadcrumbs[1].text = this.client.name;
        moveScreenToUp();
      }
      this.loading = false;
      this.setLoading(false);
    },

    goBackToResellerClients() {
      this.$router.push({
        path: "/reseller/clients",
      });
    },

    goBack() {
      this.$router.push({
        path: "/reseller/client",
        query: { domain: this.main_domain, key: this.$route.query.key },
      });
    },

    redirectToClientPage(client_plan) {
      this.$router.push({
        path: "/reseller/client",
        query: {
          domain: this.clientMainDomain,
          key: this.clientKey,
          plan: client_plan,
        },
      });
    },

    // TODO: descontinuar e usar helper de payload do companyPlan.js e actions de plans.js
    async hirePlan() {
      this.loadingPurchase = true;
      this.setLoading(true);
      this.showConfirmNewPlan = false;

      const isNewPlan = !this.isEditingTrial;

      const payload = getHirePlanPayload(
        this.clientKey,
        this.planInCart,
        this.planCartTotal,
        this.servicesCart,
        isNewPlan,
        this.isConecta
      );

      if (this.isEditingTrial) {
        await this.updateHiredPlan({
          plan_key: this.selectedPlan.key,
          payload: payload,
        })
          .then(() => {
            this.redirectToClientPage(this.selectedPlan.key);
            this.orderDetails = planOrder;
            this.clearCart();
            if (this.clientMainDomain === this.mainDomain) {
              this.getContractedPlans(true);
            }
          })
          .catch((err) => {
            console.error(err);
            const { data } = err.response;
            this.setErrorMessage(data.code);
            this.showSnackBarError = true;
          });
      } else if (isNewPlan) {
        payload["hiring_type"] = this.hiringType;

        await this.hireNewPlan(payload)
          .then(async ({ data }) => {
            // Redireciona para o plano recém adicionado
            this.redirectToClientPage(data.key);
            this.orderDetails = planOrder;
            this.clearCart();
            if (this.clientMainDomain === this.mainDomain) {
              await this.getContractedPlans();
            }
          })
          .catch((err) => {
            console.error(err);
            const { data } = err.response;
            this.setErrorMessage(data.code);
            this.showSnackBarError = true;
          });
      }

      this.loadingPurchase = false;

      this.setLoading(false);
    },

    setNewInitTransaction() {
      this.orderDetails.init_transaction = formatDateForDatabase(
        this.initTransactionTip
      );
    },

    setEndTransactionByPeriod(subscription_type, date = null) {
      if (isTrial(subscription_type)) {
        return null;
      }

      date = !date ? moment() : moment(date);

      const end_transaction_by_period =
        subscription_type === SUBSCRIPTIONS.FLEX
          ? date.endOf("month")
          : date.add(1, "year").subtract(1, "days");

      return formatDateForDatabase(end_transaction_by_period);
    },

    resetOrderDates() {
      this.orderDetails.end_trial = null;
      this.orderDetails.init_billing = null;
      this.orderDetails.init_transaction = null;
      this.orderDetails.end_transaction = null;
    },
  },

  async beforeMount() {
    if (!this.isConecta) {
      this.steps = 2;
      this.setHiringType("hiring");
    }

    this.getPlans();
    this.resetPlanInCart();
    await this.startLoadClient();
    if (this.$route.query.plan) {
      await this.selectPlan(this.$route.query.plan);
    }
  },

  beforeUnmount() {
    this.clearServiceCart();
    this.clearCart();
  },
};
</script>
<style scoped>
.steps {
  max-width: 900px !important;
}
.go-to-step {
  cursor: pointer !important;
}
</style>
