var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HeaderDialog',{attrs:{"subtitle":"Aplicar desconto para este plano","title":`Plano ${_vm.planName} - ${_vm.customerName}`,"show":_vm.showEditDiscount,"width":"600","disabled-action":_vm.invalidDiscount,"action-text":"Aplicar desconto","button-class":"text-none text-subtitle-1 font-weight-medium elevation-0"},on:{"close":_vm.close,"action":_vm.applyDiscount},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',{staticClass:"mb-5"},[_vm._v(" Aplique um desconto ou valor promocional para o plano deste cliente. ")]),_c('v-scroll-y-transition',[(_vm.showRemovedMaxDiscount)?_c('Alert',{attrs:{"text":false,"dense":"","dark":"","color":"success"}},[_vm._v(" Limite de desconto máximo removido. ")]):_vm._e()],1),_c('v-row',{staticClass:"ma-0 pb-0"},[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"1","sm":"1","md":"1"}},[_c('div',{staticClass:"pr-4 pt-3"},[[_c('v-icon',{attrs:{"size":"2em","color":"accent"},domProps:{"textContent":_vm._s('mdi-currency-usd')}})]],2),_c('div',{staticClass:"pt-12"},[[_c('v-icon',{attrs:{"size":"2em","color":"accent"},domProps:{"textContent":_vm._s('mdi-percent')}})]],2)]),_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"11","sm":"11","md":"11"}},[_c('v-form',{ref:"editPlanDiscount",staticStyle:{"width":"100%"}},[_c('vuetify-money',{attrs:{"label":"Valor por licença/mês (R$)","hint":`O valor do desconto não deve ultrapassar ${_vm.maxDiscount}%.`,"placeholder":'4,99',"options":_vm.moneyOptions,"outlined":"","clearable":""},model:{value:(_vm.newPrice),callback:function ($$v) {_vm.newPrice=$$v},expression:"newPrice"}}),_c('v-text-field',{attrs:{"label":"Valor do desconto (%)","hint":_vm.maxDiscount === 35
                ? `O valor do desconto não deve ultrapassar ${_vm.maxDiscount}%.`
                : '',"max":_vm.maxDiscount,"success":_vm.showRemovedMaxDiscount,"type":"number","min":"0","outlined":"","clearable":"","rules":[_vm.minAndMaxDiscountRule]},model:{value:(_vm.updatedDiscount),callback:function ($$v) {_vm.updatedDiscount=$$v},expression:"updatedDiscount"}}),(_vm.newDiscount > _vm.maxDiscount && _vm.newDiscount <= 100)?_c('v-btn',{staticClass:"elevation-0 mb-3",attrs:{"small":"","block":"","color":"accent"},on:{"click":function($event){_vm.showChallengeDiscount = true}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-close-circle')}}),_vm._v(" Remover limite de desconto ")],1):_vm._e(),_c('MathProblemResolver',{attrs:{"show":_vm.showChallengeDiscount,"title":"Remover limite de desconto","description":`Resolva o problema abaixo para confirmar a <b>remoção do limite de desconto</b>.`},on:{"success":_vm.validateProblem,"close":function($event){_vm.showChallengeDiscount = false}}})],1)],1)],1),_c('v-card',{staticClass:"ma-0 pt-0 mt-5 grey lighten-5 pa-4 pt-5 grey--text text--darken-2",attrs:{"outlined":""}},[_c('div',{staticClass:"mb-2 text-body-2"},[_vm._v("Total a pagar")]),_c('div',{staticClass:"text--darken-2"},[_c('div',{class:`ma-0 pa-0 text-h5 font-weight-medium ${
            _vm.updatedDiscount > _vm.maxDiscount || _vm.updatedDiscount < 0
              ? 'error--text text--lighten-1'
              : 'primary--text text--lighten-3'
          }`},[_vm._v(" "+_vm._s(_vm.priceByPeriodPreviewText)+" "),_c('v-slide-y-reverse-transition',[(_vm.showDiscountText)?_c('v-chip',{staticClass:"font-weight-medium mb-1",attrs:{"color":"success","small":""}},[_vm._v(" Desconto de "+_vm._s(_vm.discountText)+"% do valor original ")]):_vm._e()],1)],1),_c('div',{class:`ma-0 pa-0 text-body-2 mt-1 font-weight-medium ${
            _vm.updatedDiscount > _vm.maxDiscount || _vm.updatedDiscount < 0
              ? 'error--text text--lighten-1'
              : ''
          }`},[_vm._v(" "+_vm._s(_vm.totalPricePreviewText)+" ")])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }