<template>
  <div>
    <!-- WARNING: DESCONTO DE 20% -->
    <Alert
      v-if="showDiscountAlert"
      color="success"
      :class="`mx-6 mt-3 mb-4`"
      dense
      data-testid="partner-discount-alert"
    >
      Desconto aplicado!
      <span v-if="isSelfCheckout">
        Seu parceiro <b>{{ resellerInfo.name }}</b> lhe garantiu um desconto
        extra de
        <b
          >{{ (resellerInfo.discount * 100).toFixed(2) }}% em qualquer plano de
          pagamento</b
        ></span
      >
      <span v-else
        >Todos os planos adquiridos para os clientes de
        <span class="font-weight-bold">{{ resellerInfo.name }}</span
        >, receberão um desconto de
        {{ (resellerInfo.discount * 100).toFixed(2) }}%</span
      >.
    </Alert>

    <!-- WARNING: NÃO PODE EDITAR PLANO ATUAL -->
    <Alert
      v-if="isActiveInSomeSubscriptionType"
      :class="$vuetify.breakpoint.mobile ? 'mx-0' : 'mx-5'"
      color="warning"
      dense
    >
      {{
        clientHasThisPlan.subscription_type === "FLEX"
          ? warnings.flex_company_plan_active
          : warnings.annual_company_plan_active
      }}
    </Alert>

    <!-- WARNING: AVISO DE BLOQUEIO POR IMPACTAR PLANO ANUAL -->
    <Alert
      v-else-if="isDowngrade"
      :class="$vuetify.breakpoint.mobile ? 'mx-0' : 'mx-5'"
      color="warning"
      dense
    >
      Esse plano
      <span class="font-weight-bold">não pode ser contratado</span> pois você
      possui um plano superior com contrato anual ativo. Caso tenha dúvida
      contate nossa equipe comercial através do e-mail:
      <span class="font-weight-bold">{{ emails.experience }}</span>
    </Alert>

    <!-- WARNING: VALOR MÍNIMO DE COMPRA -->
    <Alert
      v-else-if="showMinValueWarning"
      :class="$vuetify.breakpoint.mobile ? 'mx-0' : 'mx-5'"
      dense
      color="warning"
    >
      <b>{{ warnings.requires_min_price }}</b>
      <span v-if="isSelfCheckout && annualIsNotElegible">
        {{ warnings.help_min_price_client }}
      </span>
      <span v-if="isSelfCheckout && !annualIsNotElegible">
        {{ warnings.continues_with_annual }}
      </span>
      <span v-else-if="!isSelfCheckout">
        {{ warnings.help_min_price_reseller }}
      </span>
    </Alert>

    <div
      :class="`d-flex ${
        $vuetify.breakpoint.xs ? 'flex-column align-center' : 'justify-center'
      } mt-2 `"
    >
      <CardPurchaseItems
        v-for="subscription in subscriptionTypes"
        :key="subscription"
        :data-testid="`purchase-plan-${subscription}`"
        :subscription-type="subscription"
        :resellerInfo="resellerInfo"
        :disableActions="disableActions"
        :disabledSelect="disableSelect(subscription)"
        @selected="userHasSelectedTheSubscriptionType"
        @update-plan="setValues"
      />
    </div>
  </div>
</template>

<script>
import { getTotalPlanPrice } from "@/helpers/services/billing";
import CardPurchaseItems from "@/components/reseller/cards/CardPurchaseItems";
import { subscriptionPlans } from "@/helpers/variables/subscriptionPlans.js";
import { STATUS, SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";
import { mapGetters } from "vuex";
import { emails } from "@/helpers/variables/contactConecta";
import { warnings } from "@/helpers/variables/warnings";
import {
  monthsByPeriod,
  planPeriodicDescription,
} from "@/helpers/variables/translateString";

export default {
  name: "SubscriptionPlans",
  components: { CardPurchaseItems },
  props: {
    disableActions: { type: Boolean, default: false },
  },
  data: () => ({
    SUBSCRIPTIONS,
    subscriptionPlans,
    planPeriodicDescription,
    STATUS,
    emails,
    warnings,
    notAttendMinValue: false,
    subscriptions: {
      ANNUAL: false,
      FLEX: false,
    },
    companyPlanValues: false,
    monthsByPeriod,
  }),

  computed: {
    ...mapGetters([
      "isSelfCheckout",
      "impactedCompanyPlans",
      "clientHasThisPlan",
      "selectedPlanProducts",
      "selectedPlanName",
      "currentSelectedPlan",
      "selectedResellerClient",
      "usersNumber",
      "isConecta",
      "company",
      "currentLicensesInCart",
      "selectedUsersNumber",
    ]),

    showDiscountAlert() {
      return this.resellerInfo.discount > 0;
    },

    resellerInfo() {
      return this.isSelfCheckout
        ? this.company.reseller_company
        : this.selectedResellerClient.reseller_company;
    },

    isDirectPartner() {
      if (this.isSelfCheckout) {
        return (
          this.company.reseller_company &&
          this.company.reseller_company.type === "DIRECT"
        );
      }
      return (
        this.selectedResellerClient &&
        this.selectedResellerClient.reseller_company.type === "DIRECT"
      );
    },

    annualIsNotElegible() {
      return this.subscriptions.ANNUAL;
    },

    isAnnual() {
      return (
        this.companyPlanValues &&
        this.companyPlanValues.subscription_type == SUBSCRIPTIONS.ANNUAL
      );
    },

    isFlex() {
      return (
        this.companyPlanValues &&
        this.companyPlanValues.subscription_type == SUBSCRIPTIONS.FLEX
      );
    },

    minValueNotReachedWarning: {
      get() {
        return this.notAttendMinValue;
      },
      set(new_value) {
        this.notAttendMinValue = new_value;
      },
    },

    isActiveInSomeSubscriptionType() {
      if (!this.isSelfCheckout) {
        return false;
      }

      return (
        this.clientHasThisPlan &&
        [SUBSCRIPTIONS.ANNUAL, SUBSCRIPTIONS.FLEX].includes(
          this.clientHasThisPlan.subscription_type
        ) &&
        this.clientHasThisPlan.status === STATUS.ACTIVE
      );
    },

    hasActiveAnnualPlan() {
      return (
        this.clientHasThisPlan.subscription_type === "ANNUAL" &&
        this.clientHasThisPlan.status === "ACTIVE"
      );
    },

    subscriptionTypes() {
      if (this.isSelfCheckout) {
        return Object.keys(subscriptionPlans).filter(
          (item) => item !== SUBSCRIPTIONS.TRIAL
        );
      }
      return Object.keys(subscriptionPlans);
    },

    showMinValueWarning() {
      return Object.values(this.subscriptions).find((item) => item !== false);
    },

    isDowngrade() {
      if (!this.isSelfCheckout) {
        return false;
      }

      let downgrade = false;

      const annualActivePlans = this.impactedCompanyPlans.filter(
        (companyPlan) =>
          companyPlan.status === STATUS.ACTIVE &&
          companyPlan.subscription_type == SUBSCRIPTIONS.ANNUAL
      );

      annualActivePlans.some((annual_plan) => {
        if (this.currentSelectedPlan.rank <= annual_plan.plan.rank) {
          downgrade = true;
        }
      });

      return downgrade;
    },
  },

  watch: {
    selectedPlanName() {
      if (this.selectedPlanName && this.companyPlanValues) {
        this.setMinValues();
      }
    },

    currentSelectedPlan() {
      if (this.selectedPlanName && this.companyPlanValues) {
        this.setMinValues();
      }
    },
  },

  methods: {
    userHasSelectedTheSubscriptionType(payload) {
      this.$emit("select", payload);
    },

    disableSelect(subscription_type) {
      return this.subscriptions[subscription_type];
    },

    setValues(values) {
      this.companyPlanValues = values;
      this.setMinValues();
    },

    setMinValues() {
      const { subscription_type, price, discount, licenses } =
        this.companyPlanValues;

      const totalPrice = price
        ? getTotalPlanPrice(licenses, subscription_type, discount, price)
        : 0;

      if ((this.isDirectPartner || this.isConecta) && subscription_type) {
        if (price > 0) {
          this.subscriptions[subscription_type] = totalPrice < 40;
        } else {
          this.subscriptions[subscription_type] = true;
        }
      }
    },
  },
};
</script>
