<template>
  <v-dialog v-model="show" width="600">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-subtitle></v-card-subtitle>
      <v-card-text class="ma-0 pa-0 px-5 mt-3 mb-4 text-body-1">{{
        message
      }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" color="accent" width="150">{{
          btnCloseText.toUpperCase()
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AdvicePermissionDialog",
  props: {
    show: { type: Boolean, default: false },
    btnCloseText: { type: String, default: "Fechar" },
    title: { type: String, default: "Título" },
    message: { type: String, default: "Sua mensagem aqui" },
  },
};
</script>

<style></style>
