<template>
  <v-footer class="border-top">
    <v-row class="ma-0 px-0 py-3" no-gutters align="center">
      <v-col v-if="isConecta">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle> Total </v-list-item-subtitle>
            <v-list-item-title class="text-h5">
              {{ formatMoney(cartTotal) }}
              <v-chip
                v-if="hasDiscountInCart"
                color="success"
                class="font-weight-medium caption"
                small
              >
                Desconto de {{ formatMoney(discountInCart) }}
              </v-chip>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col class="d-flex align-center justify-end">
        <v-card-actions>
          <v-btn
            text
            x-large
            color="secondary lighten-2"
            rounded
            :disabled="loading || disabledCandel"
            class="font-weight-medium text-none text-body-1 px-5 mr-2"
            @click="$emit('cancel')"
          >
            Cancelar
          </v-btn>
          <v-spacer v-if="!isConecta" />
          <v-btn
            x-large
            color="accent"
            :loading="loading"
            :disabled="disabledAction"
            rounded
            elevation="0"
            class="font-weight-medium text-none text-body-1 px-6 rounded-xl"
            @click="$emit('purchase')"
          >
            Confirmar pedido
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-footer>
</template>
<script>
import { mapGetters } from "vuex";
import { formatMoney } from "@/helpers/services/utils";

export default {
  name: "CheckoutFooter",

  props: {
    loading: { type: Boolean, default: false },
    disabledAction: { type: Boolean, default: false },
    disabledCandel: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters([
      "isConecta",
      "discountInCart",
      "cartTotal",
      "hasDiscountInCart",
    ]),
  },

  methods: {
    formatMoney,
  },
};
</script>
