<template>
  <div>
    <v-menu
      :ref="id"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :disabled="!isConecta || disabled || readonly"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          :data-testid="testId"
          :outlined="outlined"
          :dense="dense"
          :disabled="!isConecta || disabled || readonly"
          :label="label"
          :value="date"
          :placeholder="
            !date
              ? 'Não há definição de data para esta modalidade'
              : placeholder
          "
          :hide-details="hideDetails"
          :style="`${styles}`"
          readonly
          persistent-placeholder
        >
          <template #label>
            {{ label }}
            <span v-if="required" class="error--text font-weight-medium">
              *
            </span>
          </template>

          <template v-slot:append>
            <v-tooltip v-if="info" top max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-text="'mdi-information-slab-circle-outline'"
                />
              </template>
              <span>
                {{ info }}
              </span>
            </v-tooltip>

            <v-tooltip v-if="customInfo && isConecta" top max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-text="customInfo.icon"
                  :color="customInfo.color"
                />
              </template>
              <span>
                {{ customInfo.label }}
              </span>
            </v-tooltip>

            <v-icon
              v-if="showDateIcon || appendIcon"
              v-text="showDateIcon ? 'mdi-calendar' : appendIcon"
            />
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="localDate"
        :color="autoColorMode ? primaryColor : color"
        :header-color="autoColorMode ? primaryColor : color"
        :min="minDate || ''"
        :max="maxDate || ''"
        @change="save"
      />
    </v-menu>
  </div>
</template>
<script>
import {
  formatDate,
  formatDateForDatabase,
  today,
} from "@/helpers/services/utils";
import { mapGetters } from "vuex";

export default {
  name: "DatePicker",

  props: {
    appendIcon: { type: String, default: "" },
    color: { type: String, default: "auto" },
    customInfo: { type: [Boolean, Object], default: false },
    databaseFormat: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    id: { type: String, required: true },
    info: { type: [String, Boolean], default: false },
    label: { type: String, default: "" },
    outlined: { type: Boolean, default: false },
    placeholder: { type: String, default: "" },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    showDateIcon: { type: Boolean, default: false },
    styles: { type: String, default: "" },
    testId: { type: String, default: "auto" },
    value: { type: String, required: true },
    min: { type: String, default: "" },
    max: { type: String, default: "" },
    minToday: { type: Boolean, default: false },
    maxToday: { type: Boolean, default: false },
  },

  data() {
    return {
      today,
      openCloseMenu: {},
    };
  },

  computed: {
    ...mapGetters(["isConecta", "primaryColor"]),

    autoColorMode() {
      return this.color === "auto";
    },

    minDate() {
      if (this.minToday) {
        return formatDateForDatabase(today);
      }
      return this.min;
    },

    maxDate() {
      if (this.maxToday) {
        return formatDateForDatabase(today);
      }
      return this.max;
    },

    localDate: {
      get() {
        return formatDateForDatabase(this.value || today);
      },
      set(value) {
        this.$emit("update", value);
      },
    },

    date: {
      get() {
        if (this.value) {
          return formatDate(this.value);
        }
        return "";
      },
      set(value) {
        this.$emit("update", value);
      },
    },

    openHideDatePicker: {
      get() {
        return this.openCloseMenu[this.id];
      },
      set(value) {
        this.openCloseMenu[this.id] = value;
      },
    },
  },

  methods: {
    formatDate,

    save(value) {
      this.date = value;
      this.$refs[this.id].save();
    },
  },

  beforeMount() {
    this.openCloseMenu[this.id] = false;
  },
};
</script>
